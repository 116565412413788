<template>
    <button class="appearance-none rounded-none focus:outline-none" @click.prevent="onOpen">
        <slot />
    </button>
</template>

<script>
    import GLightbox from 'glightbox';

    export default {
        props: {
            elements: {
                type: Array,
                default: () => { return ([]); },
            },
            startAt: {
                type: Number,
                default: 0,
            },
            content: {
                type: String,
                default: '',
            },
            provider: {
                type: String,
                default: '',
            },
            type: {
                type: String,
                default: '',
            },
            href: {
                type: String,
                default: '',
            },
            description: {
                type: String,
                default: null,
            },
        },

        data() {
            return {
                lightbox: null,
            };
        },

        mounted() {
            if (this.elements.length > 0) {
                this.lightbox = GLightbox({
                    elements: this.elements,
                    startAt: this.startAt,
                });
            } else {
                const elem = {};

                if (this.content) {
                    elem.content = this.content;
                } else {
                    elem.href = this.href;
                    elem.type = this.type;
                }

                if (this.provider) {
                    elem.source = this.provider;
                }

                if (this.description) {
                    elem.description = this.description;
                }

                this.lightbox = GLightbox({
                    elements: [elem],
                });
            }
        },

        methods: {
            onOpen() {
                this.lightbox.open();
            },
        },
    };
</script>

<style>
    .gslide-inline {
        @apply !h-auto;
    }

    .gslide-inline .ginlined-content {
        @apply !p-0;
    }
</style>
