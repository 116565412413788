<template>
    <section>
        <slot :on-change-tab="onChangeTab" :tab="tab" />
    </section>
</template>

<script>
    export default {
        data() {
            return {
                tab: 'spotify',
            };
        },

        methods: {
            onChangeTab(tab) {
                this.tab = tab;
            },
        },
    };
</script>
