<template>
    <section>
        <slot :artist-id="artistId" :on-select-artist="onSelectArtist" />
    </section>
</template>

<script>
    export default {
        props: {
            initialArtistId: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                artistId: this.initialArtistId,
            };
        },

        methods: {
            onSelectArtist(id) {
                this.artistId = id;
            },
        },
    };
</script>

<style>
    .bit-widget-container > div:empty::after {
        content: 'This artist has no tour information available.';
    }
</style>
